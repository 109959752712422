import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "antd";
import { appConfig } from "../../../app-config";
import { APP_ROUTES } from "../../../data-access";
import "./payment-processing.scss";

const baseClass = "payment-processing";

export const PaymentProcessing = (): JSX.Element => {
  const [secondsToRedirect, setSecondsToRedirect] = useState<number>(4);
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("redirect");
  const redirectToAfterPayment = redirectUrl === "ai-job-researcher" ? APP_ROUTES.aiLeadGenerator : APP_ROUTES.billing;
  const navigate = useNavigate();

  useEffect(() => {
    if (appConfig.enableGTAG && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-11007670916/_0ExCJjate4YEIT17oAp",
      });
    }

    const intervalId = setInterval(() => {
      if (secondsToRedirect !== 0) {
        setSecondsToRedirect(secondsToRedirect - 1);
      } else {
        navigate(redirectToAfterPayment, {
          state: { allowedTransition: true },
        });
      }
    }, 200);

    return () => {
      clearInterval(intervalId);
    };
  }, [secondsToRedirect]);

  const percentage = 100 - secondsToRedirect * 25;

  return (
    <div className={baseClass}>
      <h1 className={`${baseClass}__main-title`}>Payment verification</h1>
      <Progress
        className={`${baseClass}__progress-bar`}
        type="circle"
        percent={percentage}
      />
      <h2 className={`${baseClass}__sub-title`}>Verification in progress</h2>
      <p className={`${baseClass}__paragraph`}>
        Please wait until the status changes
      </p>
    </div>
  );
};
